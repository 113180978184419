.menu:hover {
    background-color: #63d471;
    background-image: linear-gradient(315deg, #F14A0B 0%, #ED7215 74%);
    color: white;
    cursor: pointer;
}

.menu {
    float: right;
    color: black;
    height: 100%;
    padding: 24px;
    font-size: 17px;
    font-weight: bold;
}

a {
    text-decoration: none;
    color: inherit
}

.before {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0.3px 0.3px 2px gray;
    z-index: 99;
    width: 100%;
    font-size: 16px;
    color: #1760d3;
    transition: all 0.2s ease-in-out;
}

.after {
    transition: all 0.2s ease-in-out;
    /* position: fixed; */
    background-color: rgba(255, 255, 255, 0);
    z-index: 99;
    width: 100%;
    font-size: 20px;
    color: #1760d3
}

.nav-wrapper ul {
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
}

.nav-wrapper ul li:hover {
    background-image: linear-gradient(315deg, #eeeeee 0%, #F5F5F5 74%);
    color: gray;
    border-bottom-color: grey;
}

.nav-wrapper ul li {
    display: inline-block;
    padding: 18px;
    margin: 0px;
    right: 0px;
    font-weight: bold;
}

.brand-logo {
    margin-right: 43%;
}



.logo_img {
    width: 16%;
    margin-bottom: -9px;
}

.logo_img_small {
    width: 88%;
    margin-bottom: -15px;
    margin-left: -50px;
}

.particle {
    position: relative;
    width: 100%;
    height: 50%;
    /* background-image: url(img/logo/183850_raven-hd-wallpapers_1920x1200_h.jpg); */
    background-position: bottom;
    /* background-repeat: no-repeat; */
    background-color: black;
    background-size: cover;
    background: rgb(10,27,203);
    background: linear-gradient(129deg, rgba(10,27,203,1) 0%, rgba(0,212,255,1) 0%, rgba(23,222,220,1) 0%, rgba(36,101,204,1) 38%, rgba(16,2,129,1) 68%, rgba(9,0,83,1) 100%);

}
#tsparticle{
    height: 460px;
    z-index: 1;
    /* position:absolute */
}

.particle_service {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/logo/services.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.particle_port {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/logo/port.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.particle_about {
    position: relative;
    width: 100%;
    height: 50%;
    /* background-image: url(img/logo/183850_raven-hd-wallpapers_1920x1200_h.jpg); */
    background-position: bottom;
    /* background-repeat: no-repeat; */
    background-color: black;
    background-size: cover;
    background: rgb(10,27,203);
    background: linear-gradient(129deg, rgba(10,27,203,1) 0%, rgba(0,212,255,1) 0%, rgba(23,222,220,1) 0%, rgba(36,101,204,1) 38%, rgba(16,2,129,1) 68%, rgba(9,0,83,1) 100%);

}

.scientific {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/scientific.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.web {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/back1.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.port {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/back3.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}

.contact {
    position: relative;
    width: 100%;
    height: 50%;
    /* background-image: url(img/logo/183850_raven-hd-wallpapers_1920x1200_h.jpg); */
    background-position: bottom;
    /* background-repeat: no-repeat; */
    background-color: black;
    background-size: cover;
    background: rgb(10,27,203);
    background: linear-gradient(129deg, rgba(10,27,203,1) 0%, rgba(0,212,255,1) 0%, rgba(23,222,220,1) 0%, rgba(36,101,204,1) 38%, rgba(16,2,129,1) 68%, rgba(9,0,83,1) 100%);

}

.cv {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(img/code/back2.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: black;
    background-size: cover;
}